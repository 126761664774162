$white-color: #ffffff;
$main-color: #088dd3;
/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
@import "./assets/sass/plugins.scss";
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";

@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}
// @import './assets/vendors/base/vendors.bundle.css';
// @import './assets/demo/default/base/style.bundle.css';
* {
  box-sizing: border-box;
  font-family: "Helvetica Neue", sans-serif;
}

/*
bootstrap table theme
*/
.table-style {
  padding: 0 1rem;
}
.ngx-datatable.bootstrap {
  box-shadow: none;
  font-size: 0.8125rem;
}
.m-wizard.m-wizard--2
  .m-wizard__head
  .m-wizard__nav
  .m-wizard__steps
  .m-wizard__step
  .m-wizard__step-info
  .m-wizard__step-title {
  font-size: 0.6vw;
}
#title_general {
  font-size: 1.2rem !important;
  font-weight: bold !important;
  margin: 0.625rem !important;
}

.ngx-datatable.bootstrap .datatable-header {
  height: unset !important;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  vertical-align: bottom;
  padding: 0.75rem;
}
.table-responsive {
  overflow-y: hidden;
}
.ngx-datatable.bootstrap
  .datatable-header
  .datatable-header-cell
  .datatable-header-cell-label {
  line-height: 24px;
}
.cdk-virtual-scroll-content-wrapper {
  height: 100% !important;
  contain: initial !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  padding: 0.75rem;
  vertical-align: top;
  /* border-top: 1px solid #d1d4d7; */
}

.ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-row.datatable-row-even {
  background-color: #faf9fc;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: #f2f3f8;
  color: #2d2e3f;
}

.ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  text-align: left;
  vertical-align: top;
  display: flex;
  align-items: center;
  height: auto !important;
}

.ngx-datatable .datatable-footer {
  overflow: hidden !important;
}

.ngx-datatable.bootstrap .datatable-footer {
  /* background: #ebe9f2; */
  color: #3f4047;
  margin-top: -1px;
}

.ngx-datatable.bootstrap .datatable-footer .page-count {
  line-height: 3.125rem;
  height: 3.125rem;
  padding: 0 1.2rem;
}
.page-count {
  text-transform: capitalize;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
  margin: 0 0.625rem;
  vertical-align: top;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
  margin: 0.625rem 0px;
}

.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled).active
  a,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled):hover
  a {
  background-color: #2295e8;
  font-weight: bold;
  border-radius: 50%;
  color: #fff !important;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0;
  border-radius: 3px;
  margin: 0 3px;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  vertical-align: bottom;
  color: #ededed;
}

.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  .datatable-icon-left,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  .datatable-icon-skip,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  .datatable-icon-right,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  .datatable-icon-prev {
  font-size: 18px;
  line-height: 27px;
  padding: 0 3px;
}

.datatable-header {
  color: #575962;
  background: #f4f3f8;
  font-weight: 600;
}

li.pages a {
  color: #9b9da6 !important;
}
.icon-delete {
  width: 10px;
  display: flex;
  justify-content: center;
  font-size: 1rem !important;
}

/* checkbox */

.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
}

.datatable-checkbox input[type="checkbox"] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}

.datatable-checkbox input[type="checkbox"]:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #dfd8d8;
  /* border: 1px solid #f2f2f2;*/
}

.datatable-checkbox input[type="checkbox"]:checked:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #716aca;
  border-top-style: none;
  border-right-style: none;
}

.datatable-checkbox input[type="checkbox"]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  background: #fff;
  cursor: pointer;
  border: 1px solid #dfd8d8;
}

.ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  min-height: 30px;
}

.datatable-icon-skip,
.datatable-icon-right,
.datatable-icon-left,
.datatable-icon-prev {
  color: #2d2e3e80;
}

/* #2d2e3e80 */

.ip-input-container {
  display: inline-block;
  font-size: 0;
  border: 1px solid #ecedf3;
  padding: 6px 0;
  width: 100%;
}

.ip-input-container .ip-input-item {
  border: none;
  outline: 0;
  margin: 0;
  width: 60px !important;
  text-align: center;
  vertical-align: bottom;
  font-size: 13px;
}

.ip-input-container .ip-input-dot {
  display: inline-block;
  width: 2px;
  height: 2px;
  margin-bottom: 2px;
  background-color: #333;
  border-radius: 50%;
  vertical-align: bottom;
}

/* Progress */

:root {
  --color-progress-circle-number: #fff;
  --color-progress-circle: #f0f0f0;
  --color-progress-idicator: #34bfa3;
  --color-progress-number: #8b8b8b;
}

body {
  font-family: open sans condensed, sans-serif;
  background-color: #fafafa;
}

h1 {
  color: #63b8ff;
  text-align: center;
  margin-top: 9.375rem;
}

.container {
  max-width: 1105px;
  margin: 25px auto;
}

.progress-circle {
  position: relative;
  display: inline-block;
  /* margin: 1rem; */
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: --color-progress-circle;
}

.progress-circle:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: colorload 2s;
}

.progress-circle span {
  color: var(--color-progress-number);
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: -15px;
  margin-top: -15px;
  text-align: center;
  border-radius: 50%;
  background: var(--color-progress-circle-number);
  z-index: 1;
}

.progress-circle span:after {
  content: "%";
  font-weight: 600;
  color: var(--color-progress-number);
}

.progress-circle.progress-0:after {
  background-image: linear-gradient(
      90deg,
      var(--color-progress-circle) 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      90deg,
      var(--color-progress-idicator) 50%,
      var(--color-progress-circle) 50%,
      var(--color-progress-circle)
    );
}
.application-library {
  overflow-y: auto;
  overflow-x: hidden;
}

.progress-circle.progress-10:after {
  background-image: linear-gradient(
      90deg,
      var(--color-progress-circle) 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      126deg,
      var(--color-progress-idicator) 50%,
      var(--color-progress-circle) 50%,
      var(--color-progress-circle)
    );
}

.progress-circle.progress-20:after {
  background-image: linear-gradient(
      90deg,
      var(--color-progress-circle) 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      151.2deg,
      var(--color-progress-idicator) 50%,
      var(--color-progress-circle) 50%,
      var(--color-progress-circle)
    );
}

.progress-circle.progress-30:after {
  background-image: linear-gradient(
      90deg,
      var(--color-progress-circle) 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      198deg,
      var(--color-progress-idicator) 50%,
      var(--color-progress-circle) 50%,
      var(--color-progress-circle)
    );
}

.progress-circle.progress-40:after {
  background-image: linear-gradient(
      90deg,
      var(--color-progress-circle) 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      234deg,
      var(--color-progress-idicator) 50%,
      var(--color-progress-circle) 50%,
      var(--color-progress-circle)
    );
}

.progress-circle.progress-50:after {
  background-image: linear-gradient(
      -90deg,
      var(--color-progress-idicator) 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      270deg,
      var(--color-progress-idicator) 50%,
      var(--color-progress-circle) 50%,
      var(--color-progress-circle)
    );
}

.progress-circle.progress-60:after {
  background-image: linear-gradient(
      -54deg,
      var(--color-progress-idicator) 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      270deg,
      var(--color-progress-idicator) 50%,
      var(--color-progress-circle) 50%,
      var(--color-progress-circle)
    );
}

.progress-circle.progress-70:after {
  background-image: linear-gradient(
      -18deg,
      var(--color-progress-idicator) 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      270deg,
      var(--color-progress-idicator) 50%,
      var(--color-progress-circle) 50%,
      var(--color-progress-circle)
    );
}

.progress-circle.progress-80:after {
  background-image: linear-gradient(
      18deg,
      var(--color-progress-idicator) 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      270deg,
      var(--color-progress-idicator) 50%,
      var(--color-progress-circle) 50%,
      var(--color-progress-circle)
    );
}

.progress-circle.progress-90:after {
  background-image: linear-gradient(
      54deg,
      var(--color-progress-idicator) 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      270deg,
      var(--color-progress-idicator) 50%,
      var(--color-progress-circle) 50%,
      var(--color-progress-circle)
    );
}

.progress-circle.progress-100:after {
  background-image: linear-gradient(
      90deg,
      var(--color-progress-idicator) 50%,
      transparent 50%,
      transparent
    ),
    linear-gradient(
      270deg,
      var(--color-progress-idicator) 50%,
      var(--color-progress-circle) 50%,
      var(--color-progress-circle)
    );
}

@-webkit-keyframes colorload {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
}

span.select2.select2-container.select2-container--default {
  width: 100% !important;
}

.m-topbar
  .m-topbar__nav.m-nav
  > .m-nav__item
  > .m-nav__link
  .m-nav__link-icon
  > i:before {
  background: linear-gradient(
    180deg,
    #ad5beb 25%,
    #c678db 50%,
    #86e8d9 75%,
    #49bdcf 100%
  );
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* badge Table */

ngx-datatable .m-badge {
  min-width: 8px !important;
  min-height: 8px !important;
}

/* /** text capitilize for modal */

.modal-title b,
.modal-title span.m--font-primary {
  text-transform: capitalize;
  color: #5867dd !important;
  font-weight: bold;
}

#vmServerGeneral .paddUI {
  margin-left: 16.66667% !important;
  flex: 0 0 66.66667% !important;
  max-width: 66.66667% !important;
}

#vmServerGeneral .gaugeUI {
  height: 7.5rem;
}

#vmServerManage #actionManage .actionUI {
  border: 2px solid #f4f3f7;
  padding: 0.625rem 6.25rem;
  border-radius: 25px;
}

p-chart canvas {
  height: 30vh !important;
  padding: 5px 40px !important;
  margin-top: -20px !important;
}

.tool::before {
  content: attr(data-tootltip);
  z-index: 1;
  padding: 5px;
  margin-left: -12px;
  color: grey;
  position: fixed;
  border-radius: 6px;
  display: none;
  background: white;
  top: 25px;
}

.tool:hover::before {
  display: block;
}

.input-group-append > span {
  padding: 7px;
  border: 1px solid #ebedf2;
}

.m-dropdown.m-dropdown--align-center.m-dropdown--large .m-dropdown__wrapper {
  margin-left: -280px;
}

div[class^="tab-pane"] .m-section__content {
  margin-top: 0.625rem;
}

.sass .gaugeUI {
  margin: 0px auto;
  width: 70%;
}

.swal2-title .text-danger {
  word-break: break-word;
}

@media (max-width: 1200px) {
  .m-portlet .m-portlet__padding-x {
    padding: 0rem 2rem 4rem 2rem;
  }
  .m-wizard.m-wizard--2 {
    margin-top: 0rem;
    padding: 0;
  }
}

#dnsRecord .m-wizard__head {
  display: none;
}

#dnsRecord
  .ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  align-items: start;
}
.cdk-overlay-container {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 9999999 !important;
}
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 9999999 !important;
}
.user-select {
  user-select: none;
}
.password {
  text-security: disc !important;
  -webkit-text-security: disc !important;
  -mox-text-security: disc !important;
  font-family: "text-security-disc";
}
.tooltip-inner {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  background-color: #ffffff !important;
  color: #7c7979 !important;
  border-radius: 15px !important;
  padding: 5px 15px !important;
  font-size: 1.1em !important;
}

.arrow::before {
  border-top-color: #ffffff !important;
}
.select2-results__option[aria-selected="true"] {
  display: none;
}
.btn-warning {
  color: #fff !important;
  background-color: #ffb822;
  border-color: #ffb822;
}
.btn.m-btn--hover-warning {
  color: black !important;
  background-color: #fff !important;
}
.btn.m-btn--hover-warning:hover,
.btn.m-btn--hover-warning.active,
.btn.m-btn--hover-warning:focus,
.show > .btn.m-btn--hover-warning.dropdown-toggle {
  border-color: #ffb822 !important;
  background-color: #ffb822 !important;
}
.btn.m-btn--hover-warning:hover i,
.btn.m-btn--hover-warning:focus i,
.btn.m-btn--hover-warning.active i,
.btn.m-btn--hover-warning:active i,
.show > .btn.m-btn--hover-warning.dropdown-toggle i {
  color: #fff !important;
}
.btn.m-btn--hover-warning:not(.btn-secondary):not(.btn-outline-light) {
  background: 0 0;
  color: #575962;
}

.button-create {
  display: flex;
  justify-content: flex-end;
}
.edit-btn {
  width: 100px;
}
.edit-btn2 {
  width: auto !important;
}
.menu-link,
.text-muted {
  text-decoration: none !important;
}
.profile {
  font-family: Inter, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 600 !important;
}
.disabledPagination {
  color: rgb(220, 220, 220) !important;
}
.my-custom-class .tooltip-inner {
  background-color: white;
  font-size: 125%;
}
.my-custom-class.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: white;
}
.my-custom-class.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: white;
}
.my-custom-class.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: white;
}
.my-custom-class.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: white;
}
body {
  overflow-x: hidden !important;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.m-badge.m-badge--success {
  background-color: #34bfa3;
  color: #fff;
}
.m-badge.m-badge--warning {
  background-color: #fae7c7;
  color: #fff;
}
.m-badge {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  background: #eaeaea;
  color: #444;
  font-size: 0.8rem;
  line-height: 20px;
  min-height: 20px;
  min-width: 20px;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  padding: 0 2px;
}
span.tooltiptext {
  visibility: hidden;
  width: 70px;
  background-color: #292929;
  color: #ffffff;
  text-align: center;
  padding: 2px 0;
  letter-spacing: 1px;
  font-size: 1.4em;
  position: absolute;
  z-index: 1;
  margin-top: 1px;
}

span:hover .tooltiptext {
  visibility: visible;
}
.badge-status {
  position: relative;
  display: inline-block;
}
.badge-status .tooltiptext {
  visibility: hidden;
  width: 70px;
  background-color: #292929;
  color: #ffffff;
  text-align: center;
  padding: 2px 0;
  letter-spacing: 1px;
  font-size: 1.4em;
  position: absolute;
  z-index: 1;
  margin-top: 1px;
  z-index: 9999999;
}
.email {
  max-width: 160px;
}
.disabledPagination {
  color: #dcdcdc !important;
}
.btn-pagination {
  border: none;
}
.table {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
a {
  text-decoration: none;
}
.indicator-label {
  font-size: 15px;
}
@keyframes frames {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

#pause {
  display: block;
  background: rgba(0, 0, 0, 0.66) no-repeat 0 0;
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
}
#spinner {
  -webkit-animation: frames 1s infinite linear;
  animation: frames 1s infinite linear;
  background: transparent;
  border: 7px solid #f3f3f3;
  border-radius: 100%;
  border-top-color: #3498db;
  width: 50px;
  height: 50px;
  padding: 0;
  position: absolute;
  z-index: 999;
}
:host ::ng-deep .empty-row {
  font-size: 13px;
  margin: 0 1rem 0 1rem;
}
.input-search {
  margin: 30px;
  max-width: 300px;
  height: 3rem;
  border-radius: 7px;
}
.edit-icon {
  margin: 0 !important;
}
.text-primary {
  color: var(--kt-text-primary) !important;
}
.fv-plugins-message-container {
  color: red;
}
.btn-action {
  width: 90px;
}
.p-toast-top-right {
  z-index: 9999999 !important;
}
.sub-title {
  margin-top: 15px;
  font-size: 14px;
  font-weight: bold;
}
.mw-900px {
  max-width: 560px !important ;
}

.svg-icon {
  margin-right: 0px !important;
  color: #009ef7 !important;
}
.m-btn--pill {
  width: 40px;
  height: 40px;
  padding: 0 !important;
}
.btn-action {
  margin-right: 0.3rem;
}
:host
  ::ng-deep
  .ngx-datatable.material
  .datatable-summary-row
  .datatable-body-row {
  background-color: white;
}
.password {
  height: 30px;
}
.psd-append {
  display: flex;
  align-items: center;
  height: 35px !important;
  display: flex !important;
}

.btn-pagination {
  border: none;
}

a {
  text-decoration: none;
}

.mw-900px1 {
  max-width: 750px !important;
}
.helm_radio {
  margin-right: 5px;
}

.helm_input {
  margin-right: 7rem;
}

.tesxt-area {
  padding: 10px;
}

.btn-dow {
  padding-left: 1em !important;
}

.tool_input {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #009ef7 !important;
}

.mat-step-icon-state-edit {
  background-color: #009ef7 !important;
}
.mat-horizontal-stepper-header .mat-step-icon,
mat-step-header .mat-step-icon-selected {
  background-color: #009ef7 !important;
  color: #fff;
}
.depblock {
  box-shadow: 0px 0px 10px #c5baba;
  padding: 1rem;
  margin: 1rem 0;
}

.tool-r {
  margin-right: 15px;
}

button.next_btn {
  padding: 7px 12px;
  border-radius: 0px;
  min-height: 32px;
  background-color: #009ef7;
  color: #e0e0e0;
  box-shadow: none;
  border: none;
  cursor: pointer;
  outline: none;
  margin-top: 2rem;
  float: right;
}
.default {
  font-size: 8.7px;
}
.tiltfile {
  width: 100%;
}

.hel_input {
  margin-right: 5px;
  margin-bottom: 8px;
}

.helm_u {
  margin-left: 2rem;
}

.su_title {
  margin-bottom: 35px;
}

.confirm-msg {
  margin: 20px 130px;
  text-align: center;
  background-color: #f5f8fa;
}
.confirm-msg1 {
  margin: 20px 60px;
  text-align: center;
  background-color: #f5f8fa;
}
:host ::ng-deep .p-dropdown .p-dropdown-label {
  display: flex;
  align-items: center;
}

:host ::ng-deep .p-dropdown {
  height: 20px;
  width: 100%;
  margin-top: -5px;
  border: none;
  background-color: #f5f8fa;

  & .p-dropdown-label.p-placeholder {
    display: flex;
    align-items: center;
  }
}

p-dropdown {
  height: 33px;
  background-color: #f5f8fa;
  border: none;
}

button.disabled1 {
  pointer-events: none;
  background-color: #44bcff;
}
div.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.lab {
  margin-right: 5px;
}

.m-btn--pill {
  width: 35px;
  height: 35px;
  padding: 0 !important;
  background-color: white;
}

.add-ip-btn {
  padding: 0 !important;
  background-color: white;
  width: 25px;
  height: 25px;
  border: 1px solid green !important;
}

.remove-ip-btn {
  padding: 0 !important;
  background-color: white;
  width: 25px;
  height: 25px;
  border: 1px solid #ff0000 !important;
}

.form-check-input {
  width: 45px;
  height: 23px !important;
}

.title-ip {
  width: 75%;
}

.form-ip {
  padding-left: 12px;
}

.table-responsive {
  overflow-x: hidden;
}

.btn-del {
  margin-top: 2px;
}

.acl_status {
  margin-left: 5px;
}
.modal-header-details {
  display: flex;
  justify-content: flex-end;
  border-bottom: none;
  margin-bottom: -20px;
}
.created_at {
  display: flex;
  align-items: center;
  color: #249ef7;
}
#chart {
  max-width: 800px;
  margin: 10px;
}
.assign-text {
  text-align: center;
}
.assign-name {
  font-weight: bold;
  display: inline;
}
.empty-l {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.not-found {
  color: red;
  margin: 1rem;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.text-danger {
  color: #f44336;
}
.alert {
  padding: 20px;
  text-align: justify;
  border-radius: 10px;
  background-color: #f44336;
  color: black !important;
  opacity: 1;
  transition: opacity 0.6s;
  margin-bottom: 15px;
  font-weight: bold;
}
.alert.warning {
  background-color: #fae7c7;
}
.tips-pwd {
  color: #2ca1c3;
  margin-bottom: 2px;
}
.tooltiptext {
  min-width: 120px;
  background-color: #dc3545;
  color: #dc3545;
  text-align: left;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  position: relative;
  z-index: 7;
  top: 80%;
  margin-top: -12px;
}
.confirm-delete {
  position: relative !important;
  color: #dc3545;
  position: relative;
}
.graph {
  display: flex;
  flex-direction: row;
}
.reset-input,
.create-input {
  height: 35px !important;
}
/*start-stats-box-css*/
.stats-card-box {
  margin-bottom: 30px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: $white-color;
  padding: 25px 25px 25px 110px;
  border-radius: 5px;
  position: relative;

  .sub-title {
    color: #a3a3a3;
    display: block;
    margin-bottom: 5px;
    font: {
      size: 17px;
      weight: 400;
    }
  }
  h3 {
    margin-bottom: 0;
    font: {
      size: 35px;
      weight: 700;
    }
  }
}
.stats-card-box {
  margin-bottom: 25px;
  padding: 20px 20px 20px 90px;

  .icon-box {
    width: 55px;
    height: 55px;
    left: 20px;
    font-size: 30px;
  }
  .sub-title {
    font-size: 14px;
  }
  h3 {
    font-size: 25px;
  }
}
.col-lg-4,
.col-lg-6 {
  &:nth-child(1) {
    .stats-card-box {
      .icon-box {
        background-color: #13bb37;
        box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, 0.15);
      }
      .progress-list {
        .bar-inner {
          .bar {
            background: #13bb37;
          }
        }
      }
    }
  }
  &:nth-child(2) {
    .stats-card-box {
      .icon-box {
        background-color: #ff4b00;
        box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, 0.15);
      }
      .progress-list {
        .bar-inner {
          .bar {
            background: #ff4b00;
          }
        }
      }
    }
  }
  &:nth-child(3) {
    .stats-card-box {
      .icon-box {
        background-color: #4788ff;
        box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, 0.15);
      }
      .progress-list {
        .bar-inner {
          .bar {
            background: #4788ff;
          }
        }
      }
    }
  }
}
/*start-stats-box-css*/
.stats-card-box {
  margin-bottom: 30px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: $white-color;
  padding: 25px 25px 25px 110px;
  border-radius: 5px;
  position: relative;

  .icon-box {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: $main-color;
    position: absolute;
    left: 25px;
    top: 48%;
    transform: translateY(-48%);
    box-shadow: 1px 5px 24px 0 rgba(8, 141, 211, 0.15);
    text-align: center;
    font-size: 35px;
    color: $white-color;

    i {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      &.bx-paper-plane {
        top: 45%;
        transform: translateY(-45%);
        left: -3px;
      }
    }
  }
  .sub-title {
    color: #a3a3a3;
    display: block;
    margin-bottom: 5px;
    font: {
      size: 17px;
      weight: 400;
    }
  }
  h3 {
    margin-bottom: 0;
    font: {
      size: 35px;
      weight: 700;
    }
  }
}
/*start-welcome-area*/
.welcome-area {
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  background-color: $white-color !important;
  border-radius: 5px;
  padding: {
    left: 25px;
  }
  margin: {
    top: 20px;
    bottom: 30px;
  }
}
.welcome-img {
  text-align: center;
  padding-top: 20px;

  img {
    width: 100%;
  }
}
.welcome-content {
  h1 {
    font: {
      size: 35px;
      weight: 700;
    }
  }
  p {
    color: #777777;
    font: {
      size: 18px;
    }
  }
}
.welcome-area {
  margin-bottom: 25px;
  padding: {
    left: 20px;
    top: 20px;
  }
  h1 {
    font-size: 28px;
  }
  p {
    font-size: 14px;
    position: relative;
    top: -3px;
  }
}
.icon-dashboard {
  width: 2.2rem !important;
}
.welcome-msg {
  height: 59px;
}
.cluster-name {
  width: 155px;
  padding-left: 10px;
}
.b-success {
  color: #50cd89 !important;
}
.splash-screen {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Helvetica, "sans-serif";
  background-color: #f2f3f8;
  color: #5e6278;
  line-height: 1;
  font-size: 14px;
  font-weight: 400;
}

.splash-screen span {
  color: #5e6278;
  transition: none !important;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

.splash-screen img {
  margin-left: calc(100vw - 100%);
  margin-bottom: 30px;
  height: 30px !important;
}

[data-theme="dark"] .splash-screen {
  background-color: #151521;
  color: #92929f;
}

[data-theme="dark"] .splash-screen span {
  color: #92929f;
}
.scrolltop .svg-icon svg {
  color: white !important;
}
.unused-class {
  font-size: 9.5px !important;
  color: green !important;
}
.reserved-class {
  font-size: 9.5px !important;
}
.update-button {
  color: white;
  &:focus {
    color: white;
  }
}
.svg-icon-success {
  color: #50cd89 !important;
}
.filter2 {
  margin-top: 45px !important;
}
.progress-bar {
  background-color: #e9ecef !important;
}
input.key:not(:placeholder-shown) {
  font-family: "password";
  width: 100px;
  height: 16px;
}
.swal2-popup .swal2-title {
  font-size: 1.6rem !important;
}
.empty-row {
  font-size: 13px;
  margin: 0 1rem;
}
.create-btn {
  min-width: 95px !important;
}
.datatable-summary-row {
  .datatable-checkbox {
    display: none;
  }
}
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center {
  flex-direction: column-reverse;
}
.m-badge {
  min-width: 8px !important;
  min-height: 8px !important;
}
